module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-XXXXXXXXX","anonymize":true},"facebookPixel":{"pixelId":"000000000","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reticell","short_name":"Reticell","start_url":"/","lang":"es","background_color":"#fff","theme_color":"#252525","display":"minimal-ui","icon":"src/svg/favicon.svg","localize":[{"start_url":"/es/","lang":"es","name":"Reticell","short_name":"Reticell","description":"Ensayo clínico aleatorizado diseñado para evaluar el efecto de la transfusión de sangre de cordón umbilical en la incidencia de la retinopatía de la prematuridad en recién nacidos pretérmino."},{"start_url":"/en/","lang":"en","name":"Reticell","short_name":"Reticell","description":"Randomized clinical trial designed to evaluate the effect of umbilical cord blood transfusion on the incidence of retinopathy of prematurity in preterm newborns."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a63eed76c1254e0bc4e80e3441e1eedb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://reticell.eu/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
